import React from "react";
import { useTranslation } from "react-i18next";
import "./footer-section.scss";

const FooterSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <h3>{t("footer.companyName")}</h3>
        </div>
        <div className="footer-links">
          <ul>
            <li>
              <a href="/contact" className="footer-link">
                {t("footer.contact")}
              </a>
            </li>
            <li>
              <a href="/terms" className="footer-link">
                {t("footer.terms")}
              </a>
            </li>
            <li>
              <a href="/privacy" className="footer-link">
                {t("footer.privacy")}
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-social">
          <a href="https://facebook.com" className="social-link" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://twitter.com" className="social-link" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://instagram.com" className="social-link" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} {t("footer.companyName")}. {t("footer.allRightsReserved")}</p>
        <div className="recaptcha-disclaimer">
          <span>{t("footer.recaptchaProtected")} </span>
          <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
            {t("footer.privacyPolicy")}
          </a>
          <span> {t("footer.and")} </span>
          <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
            {t("footer.termsOfService")}
          </a>
          <span> {t("footer.apply")}</span>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
