import React from 'react';
import PromoSection from "./components/HomepageSections/PromoSection/PromoSection";
import AboutSection from "./components/HomepageSections/AboutSection/AboutSection";
import Header from "./components/Header/Header";
import ContactSection from './components/HomepageSections/ContactSection/ContactSection';
import FindUsSection from './components/HomepageSections/FindUsSection/FindUsSection';
import FooterSection from './components/HomepageSections/FooterSection/FooterSection';
import UpdatesSection from './components/HomepageSections/UpdatesSection/UpdatesSection';

function App() {
  return (
    <div className="App">
        <Header/>
        <PromoSection/>
        <UpdatesSection/>
        <AboutSection/>
        <ContactSection/>
        <FindUsSection/>
        <FooterSection/>
    </div>
  );
}


export default App;
