import React, {useState} from 'react';
import './header.scss';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';

function Header() {
  const { t } = useTranslation();

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <header className={"header"}>
      <div className={"header-container"}>
        <div className={"header-inner"}>
          <div className={"logo"}> {t('header.logo')}</div>
          {/* hide this on mobile */}
          <div className={"header-navigation"}>
            <nav className={"navigation-main"}>
              <div className={"primary-menu-container"}>
                <ul className={"primary-menu"}>
                  <li className={"primary-menu-item"}>
                    <a href="https://halthaconnect.com/news/">{t('header.aboutUs')}</a>
                  </li>
                  <li className={"primary-menu-item"}>
                    <a href="https://halthaconnect.com/news/">{t('header.newsUpdates')}</a>
                  </li>
                  <li className={"primary-menu-item"}>
                    <a href="https://halthaconnect.com/news/">{t('header.faq')}</a>
                  </li>
                </ul>
              </div>
            </nav>
            <nav className={"navigation-secondary"}>
              <div className={"navigation-button"}>
                <a
                  href="https://halthaconnect.com/contact-us/"
                  className="btn btn-tertiary btn-light header__navigation-btn"
                  target=""
                >
                  {t('header.contactUs')}
                </a>
              </div>
            </nav>
            <LanguageSelector />
          </div>
          {/* show this on mobile */}
          <div className={"header-mobile-button"}>
            <button type="button" className="header__navigation-btn-menu btn-primary btn" onClick={toggleMobileNav}>
                <span className="header__navigation-btn-menu--inner">
                    <span></span><span></span><span></span>
                </span>
            </button>
          </div>
        </div>
      </div>

         {/* Mobile navigation modal */}
      {isMobileNavOpen && (
        <div className="mobile-nav-modal">
          <div className="mobile-nav-content">
            <button className="mobile-nav-close" onClick={toggleMobileNav}>&times;</button>
            <nav className="mobile-navigation">
              <ul className="mobile-menu">
                <li className="mobile-menu-item">
                  <a href="#about" onClick={toggleMobileNav}>{t('header.aboutUs')}</a>
                </li>
                <li className="mobile-menu-item">
                  <a href="#news" onClick={toggleMobileNav}>{t('header.newsUpdates')}</a>
                </li>
                <li className="mobile-menu-item">
                  <a href="#faq" onClick={toggleMobileNav}>{t('header.faq')}</a>
                </li>
                <li className="mobile-menu-item">
                  <a href="#contact" onClick={toggleMobileNav}>{t('header.contactUs')}</a>
                </li>
              </ul>
            </nav>
            <LanguageSelector />
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;