import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSelector.scss'

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    console.log("Changing language to " + lng);
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language-switcher">
      <button
        onClick={() => changeLanguage('en')}
        className={i18n.language === 'en' ? 'active' : ''}
      >
        en
      </button>
      <span className="divider">|</span>
      <button
        onClick={() => changeLanguage('fr')}
        className={i18n.language === 'fr' ? 'active' : ''}
      >
        fr
      </button>
    </div>
  );
};

export default LanguageSelector;
