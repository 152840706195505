import React from 'react';
import './about-section.scss'
function AboutSection() {
    return (
        <section className={"about"}>
            <div className={"container about-container"}>
                <div>About Section</div>
            </div>
        </section>
    );
}

export default AboutSection;
