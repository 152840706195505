import React from "react";
import { useTranslation } from "react-i18next";
import "./find-us-section.scss";

function FindUsSection() {
  const { t } = useTranslation();

  return (
    <section className={"find-us"}>
      <div className={"container find-us-container"}>
        <div className="find-us-info">
          <h1>{t("findUs.title")}</h1>
          <p>
            <strong>{t("findUs.addressLabel")}</strong> {t("findUs.address")}
          </p>
          <p>
            <strong>{t("findUs.phoneLabel")}</strong> {t("findUs.phone")}
          </p>
          <p>
            <strong>{t("findUs.emailLabel")}</strong> {t("findUs.email")}
          </p>
        </div>

        <div className="find-us-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243647.99194319583!2d-118.6919207983066!3d34.020161299835566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bb14af0738e1%3A0xa1f86f9b72b6a5d2!2sLos%20Angeles%2C%20CA!5e0!3m2!1sen!2sus!4v1633063491565!5m2!1sen!2sus"
            width="100%"
            height="450"
            style={{ border: 0 }}
            loading="lazy"
            title={t("findUs.mapTitle")}
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default FindUsSection;
