import React from 'react';
import './banner.scss';

interface BannerProps {
  notices: { title: string; date: string; message: string }[];
}

const Banner: React.FC<BannerProps> = ({ notices }) => {
  return (
    <div className="notice-banner">
      <div className="banner-container">
        {notices.map((notice) => (
          <div className="banner">
            <p className="banner-date">{notice.date}</p>
            <h2 className="banner-title">{notice.title}</h2>
            <p className="banner-message">{notice.message}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Banner;
