import React from 'react';
import './updates-section.scss';
import Banner from './Banner';

function WhySection() {
    const notices = [
        {
            "title": "Important Notice",
            "date": "14 November, 2024",
            "message": "Our office hours have changed. We are now open from 9 AM to 5 PM on weekdays." 
        },
        // {
        //     "title": "Important Notice",
        //     "date": "14 November, 2024",
        //     "message": "Our office hours have changed. We are now open from 9 AM to 5 PM on weekdays." 
        // }
    ];

    return (
        <section className={"updates-section"}>
            <div className={"container updates-container"}>
                <div className='updates-row'>
                    <div className='col-md-8'>
                        <h1 className='updates-title'>Latest Updates</h1>
                    </div>
                </div>
                <div className='updates-row'>
                    <Banner notices={notices}/>
                </div>
            </div>
        </section>
    );
}

export default WhySection;
