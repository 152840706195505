import {initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBoHnaT4oucrgIL9UNV4rlTzCIdX9HyZEg",
  authDomain: "pom-medical-site.firebaseapp.com",
  projectId: "pom-medical-site",
  storageBucket: "pom-medical-site.appspot.com",
  messagingSenderId: "654462018072",
  appId: "1:654462018072:web:c0a9109ae8c10b849bd96d",
  measurementId: "G-7K06BEFDLS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const FirestoreAnalytics = getAnalytics(app);
export const FirestoreDB = getFirestore(app);
