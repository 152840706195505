import React from "react";
import "./promo-section.scss"; // Use .css if not using a preprocessor
import { useTranslation } from "react-i18next";

function PromoSection() {
  const { t } = useTranslation();

  return (
    <section className="promo">
      <div className="promo-container">
        <div className="promo-row">
          <div className="col-md-8">
            <h1 className="promo-title">
              {t('promoSection.title')}
            </h1>
            <p className="promo-desc">
              {t('promoSection.description')}
            </p>
            <button className="btn btn-secondary btn-light promo-btn">
              {t('promoSection.buttonText')}
            </button>
          </div>
          <div className="col-md-4">
            <img
              className="promo-image"
              src="https://placehold.co/600x400/EEE/31343C"
              alt={t('promoSection.imageAlt')}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default PromoSection;