import React, { useState } from "react";
import EmailUploader from "./Email/EmailUploader";
import EmailBuilder from "./Email/EmailBuilder";
import "./contact-section.scss";
import Modal from "../../Modals/Modal";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";  

interface ContactFormValues {
  name: string;
  email: string;
  messageBody: string;
}

const ContactForm: React.FC = () => {
  const emailUploader = new EmailUploader();
  const { t } = useTranslation();

  const recaptchaRef = React.useRef<ReCAPTCHA>();

  const [formValues, setFormValues] = useState<ContactFormValues>({
    name: "",
    email: "",
    messageBody: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handlePrivacyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrivacyChecked(event.target.checked);
  };

  const handleSubmitWithReCAPTCHA = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    // Get the reCAPTCHA token
    recaptchaRef?.current?.reset();
    const token = await recaptchaRef?.current?.executeAsync().catch((err) => {
      console.error("reCAPTCHA error:", err);
      setError(t("contactForm.captchaError")); // Set specific error message for reCAPTCHA failure
      return null;
    });
  
    if (!token) {
      return;
    } else if (parseFloat(token) < 0.5) {
      setError(t("contactForm.captchaLowScoreError"));
      return; 
    }

    const email = new EmailBuilder()
      .setSubject("Website Contact Form Email")
      .setBody(formValues.messageBody)
      .setFrom(formValues.name)
      .setFromEmail(formValues.email)
      .build();

    try {
      await emailUploader.uploadEmail(email);

      setSuccess(t("contactForm.successMessage"));
      setFormValues({ name: "", email: "", messageBody: "" });
      setError(null);
    } catch (error) {
      setError(t("contactForm.errorMessage"));
      setSuccess(null);
    }
  };

  return (
    <div className={"form-container"}>
      <h1 className={"title"}>{t("contactForm.title")}</h1>
      <div className={"description"}>
        {t("contactForm.description")}
      </div>

      <form onSubmit={handleSubmitWithReCAPTCHA} className={"form"}>
        <div className="row">
          <div className="input-container">
            <input
              type="text"
              id="form-name"
              name="name"
              className="form-input"
              placeholder={t("contactForm.namePlaceholder")}
              value={formValues.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-container">
            <input
              type="email"
              id="form-email"
              name="email"
              placeholder={t("contactForm.emailPlaceholder")}
              className="form-input"
              value={formValues.email}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className={"input-container"}>
            <textarea
              id="form-messageBody"
              name="messageBody"
              className="form-input"
              placeholder={t("contactForm.messagePlaceholder")}
              value={formValues.messageBody}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="row">
          <div className="input-container">
            <input
              type="checkbox"
              id="privacy-policy"
              name="privacyPolicy"
              checked={isPrivacyChecked}
              onChange={handlePrivacyChange}
            />
            <label htmlFor="privacy-policy">
              {t("contactForm.privacyPolicyAgreement")}
              <button
                type="button"
                className="privacy-link"
                onClick={() => setIsModalOpen(true)}
              >
                {t("contactForm.privacyPolicyLink")}
              </button>
            </label>
          </div>

          <button
            type="submit"
            disabled={!isPrivacyChecked}
            className={isPrivacyChecked ? "form-submit-enabled form-submit" : "form-submit"}
          >
            {t("contactForm.submitButton")}
          </button>

          {error && (
            <div className="global-message error">
              <div className="icon">
                <FaTimesCircle />
              </div>
              <div className="message">{error}</div>
            </div>
          )}

          {success && (
            <div className="global-message success">
              <div className="icon">
                <FaCheckCircle />
              </div>
              <div className="message">{success}</div>
            </div>
          )}

          <ReCAPTCHA
            ref={recaptchaRef as React.RefObject<ReCAPTCHA>}
            size="invisible"
            sitekey="6Leyj3oqAAAAAHjtP64T-tKKB8e0sOFC4XzgNLqD"
          />
        </div>
      </form>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>{t("contactForm.privacyPolicyLink")}</h2>
        <p>Your privacy policy content goes here...</p>
      </Modal>
    </div>
  );
};

export default ContactForm;